import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Slider from "react-slick"

import TemplateWrapper from '../components/layout'
import Title from '../components/title'
import Player from '../components/Video/player'

let Masonry = {}, imagesloaded = {}
try {
  Masonry = require('masonry-layout')
  imagesloaded = require('imagesloaded')
} catch(e) { }

class SingleCaseStudy extends Component {

  state = {
    active: false,
    episodeActive: false,
    lightbox: {
      active: false,
      url: '',
    },
    currentEpisode: null,
  }

  constructor(props) {
    super(props);

    this._hideEpisodeMenu = this._hideEpisodeMenu.bind(this);
    this._showEpisodeMenu = this._showEpisodeMenu.bind(this);
  }

  componentDidMount() {
    const grid = document.querySelector('.r3__gallery')
    imagesloaded(grid, () => {
      const msnry = new Masonry(grid, {
        itemSelector: '.r3__gallery__image',
        columnWidth: '.r3__gallery__image',
        gutter: 24,
        percentPosition: true,
      })

      msnry.once('layoutComplete', () => {
        grid.classList.add('load')
      })

      msnry.layout()
    })
  }

  _showEpisodeMenu() {
    this.setState({episodeActive: true});
  }

  _hideEpisodeMenu() {
    this.setState({episodeActive: false});
  }

  backgroundImage(img) {
    let bgStyle = {
      backgroundImage: 'url('+img+')'
    }
    return bgStyle;
  }

  openLightbox = url => event => {
    let lightbox = {
      active: true,
      url,
    }
    this.setState({ lightbox })
  }

  closeLightbox = () => event => {
    let { lightbox } = this.state
    lightbox.active = false
    this.setState({ lightbox })
  }

  render() {

    let { currentEpisode, lightbox } = this.state;

    let data = this.props.data.wordpressWpCaseStudy;

    if (data.acf.episodes && currentEpisode !== null) {
      data.acf.episodes[currentEpisode].video_url = data.acf.episodes[currentEpisode].episode_url
      data.acf.episodes[currentEpisode].video_placeholder = data.acf.episodes[currentEpisode].episode_placeholder
    }

    if (typeof data.acf.video_placeholder === 'object' && data.acf.video_placeholder !== null) {
      data.acf.video_placeholder = data.acf.video_placeholder.source_url
    }

    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      cssEase: 'ease',
      customPaging: (i) => {
        return <span>{i + 1}/{data.acf.slider ? data.acf.slider.length : 0}</span>;
      }
    };

    var episodeSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      cssEase: 'ease',
      centerMode: true,
      centerPadding: '24px'
    };

    return (
      <TemplateWrapper {...this.props}>
        <div>
          <Helmet bodyAttributes={{ class: 'single-cs' }}>
            <title>{ data.yoast_meta.yoast_wpseo_title }</title>
            <meta name="description" content={ data.yoast_meta.yoast_wpseo_metadesc } />
          </Helmet>

          <div className="r3__head r3--dark r3__cs-header">
            { currentEpisode !== null ?
              <Player {...data.acf.episodes[currentEpisode]} controls={true} autoplay={true} /> :
              <Player {...data.acf} controls={true} />
            }
            <h1 className="r3__cs-title">{ data.acf.title }</h1>
            { data.acf.episodes ?
              <div className="r3__more-eps">
                <span className="r3__more-eps-toggle" onClick={this._showEpisodeMenu}>
                  <span className="icon">→</span> More episodes
                </span>
                { currentEpisode !== null &&
                  <span className="r3__more-eps-chosen">
                    <span className="number">E{ currentEpisode + 1 }</span>
                    <span className="name">{ data.acf.episodes[currentEpisode].title }</span>
                  </span>
                }
              </div> : ''
            }

            <div className={ this.state.episodeActive ? 'r3__more-eps-list active' : 'r3__more-eps-list' }>
              <span className="r3__more-eps-toggle" onClick={this._hideEpisodeMenu}>
                { data.acf.title } — All Episodes <span className="icon">→</span>
              </span>
              <ul>
                { data.acf.episodes ? data.acf.episodes.map((ep, i) => {
                  return (
                    <li key={i} className={i == currentEpisode ? 'active': ''} onClick={() => this.setState({ currentEpisode: i })}>
                      <span className="thumb" style={this.backgroundImage(ep.episode_placeholder)}></span>
                      <span className="number">E{ i+1 }</span>
                      <span className="name">{ ep.title }</span>
                    </li>
                  )
                }) : '' }
              </ul>
            </div>
          </div>

          { data.acf.gallery ?
            <div className="r3__section r3--dark">
              <div className="r3__content">
                <div className="r3__gallery">
                  { data.acf.gallery.map((url, i) => {
                    let props = {
                      key: i,
                      style: this.backgroundImage(url.source_url),
                      className: "r3__gallery__image",
                      onClick: this.openLightbox(url.source_url),
                    }
                    if (i % 5 === 2) props.className += " r3__gallery__image--double"
                    return <div {...props} title={url.title} />
                  }) }
                </div>
              </div>
            </div> : ''
          }

          <div className="r3__section">
            <div className="r3__content">
              <Title text={ data.acf.title } />
              <div className="r3__narrow">
                <p className="r3__introduction" dangerouslySetInnerHTML={{__html: data.acf.intro}}></p>
              </div>
            </div>
          </div>

          { data.acf.slider ?
            <div className="r3__section r3__section--slider">
              <div className="r3__content">
                <Slider {...settings}>
                  { data.acf.slider.map((url, i) => { return <img src={url.source_url} alt={url.title} key={i} /> }) }
                </Slider>
              </div>
            </div> : ''
          }

          { data.acf.content &&
            <div className="r3__section">
              <div className="r3__content">
                <div className="r3__narrow" dangerouslySetInnerHTML={{__html: data.acf.content }} />
              </div>
            </div>
          }

          <div className="r3__section r3--dark r3__episodes">
            <div className="r3__content r3__narrow">
              { data.acf.episodes ?
                <Slider {...episodeSettings}>
                  { data.acf.episodes.map((ep, i) => {
                    let onClick = () => {
                      this.setState({ currentEpisode: i });
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      });
                    }
                    return (
                      <div className="episode" key={i}>
                        <div className="episode__video">
                          <img src={ep.episode_placeholder.source_url} alt={`${data.acf.title} - ${ep.title}`} />
                          <div className="r3__video__play" onClick={onClick}></div>
                        </div>
                        <div className="episode__details">
                          <h4>{ep.title}</h4>
                          <p dangerouslySetInnerHTML={{__html: ep.content}} />
                        </div>
                      </div>
                    )
                  }) }
                </Slider> : ''
              }
            </div>
          </div>

          { data.acf.awards ?
            <div className="r3__section">
              <div className="r3__content">
                <h3>Awards.</h3>
                <div className="r3__partners">
                  { data.acf.awards.map((el, i) => {
                    return <img src={el.image.source_url} alt={el.name || el.image.title} key={i} />
                  }) }
                </div>
              </div>
            </div> : ''
          }

          <div className={`r3__lightbox ${lightbox.active && 'r3__lightbox--active'}`} onClick={this.closeLightbox()}>
            <img src={lightbox.url} />
          </div>

        </div>
      </TemplateWrapper>
    )
  }
}

export default SingleCaseStudy

export const pageQuery = graphql`
  query currentCaseStudyQuery($id: String!) {
    wordpressWpCaseStudy(id: { eq: $id }) {
      title,
      wordpress_id,
      slug,
      acf {
        video_url
        video_placeholder {
          source_url
        }
        gallery {
          source_url
          title
        }
        title
        intro
        content
        slider {
          source_url
          title
        }
        episodes {
          title
          excerpt
          content
          episode_url
          episode_placeholder {
            source_url
          }
        }
        awards {
          link
          image {
            source_url
            title
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
